<template>
    <div class="page-section">
        <div class="section-title-wrapper">
            <Skeletor class="section-title" width="30%" />
            <Skeletor class="dosiin_slider-controls" width="20%"/>
        </div>
        <div class="grid grid-50">
            <div class="grid-item dosiin_brand-list" v-for="i in 4" :key="i">
                <BrandItemWithProduct />
            </div>
        </div>
    </div>
</template>

<script>
    import ProductItem from '@/components/skeletons/product/ProductItem';
    import BrandItemWithProduct from '@/components/skeletons/brand/BrandItemWithProduct';
    export default {
        components:{
            BrandItemWithProduct,
        },

    }
</script>