<template>
  <div class="product-main-page main-page">
    <div class="product-page-header_wrap">
      <h1 class="title">Thương hiệu</h1>
      <h2 class="subtitle">Chào mừng bạn đến trang sản phẩm của Dosiin</h2>
    </div>

    <MainSlider
      :banners="state.banners"
      :options="options"
      :height="188"
      sectionClass="product-main-page-section main-slider-home"
      wrapperClass="product-main-page-slider dosiin_product-main-page"
      swiperItemImageClass="item-images-slider"
      :templateBlock="false"
    />

    <!-- <Intersection @onIntersection="onIntersectionBrandWithBanner()">
      <SliderBrandSection
        title="Thương hiệu mới"
        link="/brands-newarrival"
        sectionClass="brand-main-page-section"
        :brands="state.brandsTopBanner"
      />
    </Intersection> -->

    <Intersection @onIntersection="onIntersectionBrandHightlight()">
      <BrandVerticalSkeleton v-if="state.brandsHightlight.length === 0" />
      <section v-else class="page-section">
        <div class="section-title-wrapper">
          <h2 class="section-title">Thương hiệu nổi bật</h2>
        </div>
        <div class="dosiin_brand-slider-vertical swiper-filter-container">
          <ul class="brand-highlight_list">
            <li
              class="brand-highlight-item_wrapper dosiin_brand-follow-wrapper brand-item"
              v-for="(brand, i) in state.brandsHightlight"
              :key="i"
            >
              <div class="brand-highlight-item_img-wrapper brand-item_img-wrapper">
                <BrandLogo :brand="brand" :width="62" :height="62" />
              </div>
              <div class="brand-highlight-item_info">
                <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                  <h5
                    class="brand-item_title"
                    :title="brand.company"
                    v-text="brand.company"
                  ></h5>
                </router-link>
                <dl class="brand-item_social">
                  <dd class="brand-highlight-item_n-followers">
                    <span class="dosiin_brand-follow-number">{{
                      brand.follow_count
                    }}</span>
                    người theo dõi
                  </dd>
                  <dd class="brand-highlight-item_n-likes">
                    {{ brand.like_count }} người thích trang
                  </dd>
                </dl>
              </div>
              <div class="brand-highlight-item_follow-btn">
                <FollowButton
                  @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                  class="brand_follow-btn primary-button button"
                  followable_type="brand"
                  :followable_id="brand.company_id"
                  :is_follow="brand.is_follow"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="seemore-btn">
          <router-link
            :to="{ name: 'brands-highlight' }"
            class="primary-button button"
            title="Thương hiệu nổi bật"
          >
            <span class="primary-link_text">Xem thêm</span>
            <i class="dsi dsi-act-arrow-forward"></i>
          </router-link>
        </div>
      </section>
    </Intersection>

    <Intersection @onIntersection="onIntersectionBrandBestselling()">
      <BrandVerticalRanking
        title="Top thương hiệu"
        link="/brands-bestselling"
        :brands="state.brandsBestSelling.slice(0, 5)"
      />
    </Intersection>

    <Intersection>
      <div class="brand-list-section">
        <div class="brand-list">
          <div class="brand-list-header">
            <h2 class="brand-main-page-section_title">Thương hiệu</h2>
          </div>
          <!-- 
                    <div class="brand-list_alphabet-filter">
                        <div class="dosiin_alphabet-filter-swiper alphabet-filter">
                            <ul class="swiper-wrapper dosiin_brand-main-alphabet-tabs">
                                <li class="swiper-slide _letter">
                                    <a class="dosiin_filter-brand-by-apl">A</a>
                                </li>
                            </ul>
                        </div>
                    </div> -->

          <BrandLoaderWithProduct
            :params="{
              getBrand: true,
              get_banner: true,
              get_products: true,
              get_follow: true,
              page: 1,
              items_per_page: 8,
              use_caching: true,
              sort_by: 'random'
            }"
          />
        </div>
      </div>
    </Intersection>
  </div>
</template>

<script>
import SliderBrandSection from "@/components/sliders/BrandsHorizon";
import BrandLogo from "@/components/images/BrandLogo";
import BrandVerticalSkeleton from "@/components/skeletons/brand/BrandsHightlight";
import BrandVerticalRankingSkeleton from "@/components/brand/BrandVerticalRanking";
import BrandLoaderWithProductsSkeletons from "@/components/skeletons/sections/BrandWithProduct";
import BrandVerticalRanking from "@/components/brand/BrandVerticalRanking";
import BrandLoaderWithProduct from "@/components/brand/BrandLoaderWithProduct";
import MainSlider from "@/components/sliders/MainSlider";
import { inject } from "vue";

export default {
  components: {
    BrandLogo,
    SliderBrandSection,
    BrandVerticalSkeleton,
    BrandVerticalRankingSkeleton,
    BrandLoaderWithProductsSkeletons,
    BrandVerticalRanking,
    BrandLoaderWithProduct,
    MainSlider,
  },
  setup() {
    const state = inject("state");
    const onIntersectionBrandWithBanner = inject("onIntersectionBrandWithBanner");
    const onIntersectionBrandHightlight = inject("onIntersectionBrandHightlight");
    const onIntersectionBrandBestselling = inject("onIntersectionBrandBestselling");
    const options = {
      autoplay: {
        delay: 3500,
      },
      loop: true,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
    };
    // const onIntersectionBrandsLoader = inject('onIntersectionBrandsLoader');
    return {
      state,
      onIntersectionBrandWithBanner,
      onIntersectionBrandHightlight,
      onIntersectionBrandBestselling,
      options,
      // onIntersectionBrandsLoader
    };
  },
};
</script>
