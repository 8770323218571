<template>
  <BrandMB v-if="layout == 4" />
  <BrandPC v-else />
</template>

<script>
import BrandMB from "@/pages/mb/Brands";
import BrandPC from "@/pages/pc/Brands";
import { provide, reactive } from "vue";
import DataService from "@/services/dosiin";
import BlockService from "@/services/block";
import BrandServices from "@/services/brand";
import { serialize } from "@/utlis/serialize";

export default {
  name: "brand",
  components: {
    BrandMB,
    BrandPC,
  },
  setup() {
    getBanners();

    const state = reactive({
      brandsTopBanner: [],
      brandsHightlight: [],
      brandsBestSelling: [],
      loadBrands: false,
      banners: [],
    });

    async function onIntersectionBrandWithBanner() {
      const response = await DataService.fetchData(
        serialize({
          getBrand: true,
          get_banner: true,
          get_follow: true,
          type: "highlight",
          limit: 5,
        })
      );

      if (response.data) {
        state.brandsTopBanner = Object.values(response.data.brands);
      }
    }

    async function onIntersectionBrandHightlight() {
      const response = await DataService.fetchData(
        serialize({
          getBrand: true,
          get_banner: true,
          get_follow: true,
          type: "highlight",
          limit: 50,
        })
      );

      if (response.data) {
        state.brandsHightlight = Object.values(response.data.brands).slice(0, 9);
      }
    }

    async function onIntersectionBrandBestselling() {
      const response = await DataService.fetchData(
        serialize({
          getBrand: true,
          get_banner: true,
          get_follow: true,
          type: "bestselling",
          limit: 9,
        })
      );

      if (response.data) {
        state.brandsBestSelling = Object.values(response.data.brands);
      }
    }

    async function getBanners() {
      // const response = await BlockService.fetchBlocks(id);

      // if (response.data) {
      //   state.banners = response.data;
      // }

      const response = await BrandServices.fetchBrands(
        serialize({
          sort_by: "random",
          get_banner: true,
          limit: 5,
        })
      );

      if (response && response.data) {
        state.banners = response.data.brands;
      }
    }

    // async function onIntersectionBrandsLoader(){
    //     state.loadBrands = true;
    //     const response = await BrandServices.fetchBrands(serialize({
    //         'getBrand'  : true,
    //         'get_banner' : true,
    //         'get_products' : true,
    //         'get_follow' : true,
    //         'type' : 'bestselling',
    //     }));

    //     if(response.data){
    //         state.brands = Object.values(response.data.brands);
    //         state.brands_pagination = response.data.params;
    //     }
    // }

    provide("state", state);
    provide("onIntersectionBrandWithBanner", onIntersectionBrandWithBanner);
    provide("onIntersectionBrandHightlight", onIntersectionBrandHightlight);
    provide("onIntersectionBrandBestselling", onIntersectionBrandBestselling);
    // provide('onIntersectionBrandsLoader' , onIntersectionBrandsLoader);
  },
};
</script>
