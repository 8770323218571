<template>
  <InfiniteLoad
    @infinite-scroll="checkIsLoadingNextPage"
    :isLoading="state.lazyLoad"
    :noResult="state.endLoad"
  >
    <BrandItemWithProduct v-if="state.loading" />
    <div v-else :class="layout == 4 ? 'page-section' : ''">
      <ul :class="gridClass">
        <template v-for="(brand, i) in state.brands" :key="i">
          <template v-if="Object.keys(brand.products).length > 0">
            <li class="dosiin_brand-list" :class="itemClass">
              <div class="brand-list-item_wrapper">
                <div class="brand-item_header">
                  <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                    <div class="brand-item_banner-wrapper">
                      <img
                        v-lazy="{
                          src: _bannerImage(
                            brand.banner_shop[Object.keys(brand.banner_shop)[0]]
                          ),
                          loading: _bannerDefault,
                          error: _bannerDefault,
                        }"
                        :height="layout == 4 ? '190' : '268'"
                      />
                      <div class="brand-item_img-overlay"></div>
                    </div>
                  </router-link>
                  <div class="brand-item_description brand-item">
                    <div class="brand-item_img-wrapper">
                      <BrandLogo :brand="brand" :width="62" :height="62" />
                    </div>
                    <div class="brand-item_info">
                      <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                        <h5
                          class="brand-item_title dosiin_truncate-title-brand"
                          v-text="brand.company"
                        ></h5>
                      </router-link>
                      <dl class="brand-item_social">
                        <dd>
                          <span
                            class="dosiin_brand-follow-number"
                            v-text="brand.follow_count"
                          ></span>
                          người theo dõi
                        </dd>
                        <dd>
                          <strong
                            v-text="brand.products_count"
                            class="dosiin_mr-1"
                          ></strong
                          >sản phẩm
                        </dd>
                      </dl>
                    </div>
                    <div class="brand-item_follow">
                      <router-link
                        :to="_brandUrl(brand.seo_name)"
                        :title="brand.company"
                        class="brand_follow-btn primary-button button"
                      >
                        <i class="dsi dsi-act-manage"></i>
                        <span class="follow-btn-text">Ghé shop</span>
                      </router-link>
                    </div>
                  </div>
                </div>
                <ul class="grid grid-33" v-if="brand.products">
                  <li class="grid-item" v-for="(product, j) in brand.products" :key="j">
                    <ProductItem :product="product" />
                  </li>
                </ul>
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </InfiniteLoad>
</template>

<script>
import ProductItem from "@/components/product/ProductItem";
import InfiniteLoad from "@/components/common/InfiniteScroll";
import BrandItemWithProduct from "@/components/skeletons/brand/BrandItemWithProduct";
import { reactive, ref, onMounted } from "vue";
import { serialize } from "@/utlis/serialize";
import BrandServices from "@/services/brand";
export default {
  components: {
    BrandItemWithProduct,
    ProductItem,
    InfiniteLoad,
  },
  props: {
    brands: {
      type: Array,
      default: [],
    },
    params: {
      type: Object,
      default: {
        page: 1,
      },
    },
    gridClass: {
      type: String,
      default: "",
    },
    itemClass: {
      type: String,
      default: "grid-item",
    },
  },
  setup(props) {
    const state = reactive({
      loading: true,
      lazyLoad: false,
      endLoad: false,
      brands: [],
    });

    const pagination = ref({});
    pagination.value = props.params;
    const checkIsLoadingNextPage = () => {
      if (!state.lazyLoad && !state.endLoad) {
        getBrands();
      }
    };

    onMounted(() => {
      getBrands();
    });

    async function getBrands() {
      state.lazyLoad = true; // deny multi request
      const response = await BrandServices.fetchBrands(serialize(pagination.value));
      state.brands.push(...response.data.brands);

      // // end of post data
      if (response.data.brands.length === 0) {
        state.endLoad = true;
        state.lazyLoad = false;
        return;
      }

      // pagination.value = response.data.params;
      pagination.value.page = pagination.value.page + 1;
      // load status false => ready to load next reqeust , true => is loading
      state.lazyLoad = false;
      // load skeletons when init page load
      state.loading = false;
    }

    return {
      state,
      checkIsLoadingNextPage,
    };
  },
};
</script>
