<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <MainSlider
      :banners="state.banners"
      :options="options"
      :height="425"
      sectionClass="page-section dosiin_pl-0 dosiin_pr-0"
      wrapperClass="main-page-slider overflow"
      swiperClass="swiper-wrapper dosiin_swiper-effect-coverflow"
      :templateBlock="false"
    />

    <!-- <Intersection @onIntersection="onIntersectionBrandWithBanner()">
      <SliderBrandSection
        title="Thương hiệu mới"
        sectionClass="brand-main-page-slider dosiin_mb-16"
        :brands="state.brandsTopBanner"
        link="/brands-newarrival"
      />
    </Intersection> -->
    <Intersection @onIntersection="onIntersectionBrandHightlight()">
      <BrandVerticalSkeletonPC v-if="state.brandsHightlight.length === 0" />
      <section v-else class="page-section">
        <div class="section-title-wrapper">
          <h2 class="section-title">Thương hiệu nổi bật</h2>
          <div class="product-see-all">
            <router-link
              :to="{ name: 'brands-highlight' }"
              class="primary-button"
              title="Xem thêm sản phẩm"
            >
              <span class="primary-link_text">Xem tất cả</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
          </div>
        </div>
        <div class="grid grid-33">
          <div
            class="grid-item brand-highlight_list"
            v-for="(brand, i) in state.brandsHightlight"
            :key="i"
          >
            <div class="brand-highlight-item_wrapper brand-item">
              <div class="brand-highlight-item_img-wrapper brand-item_img-wrapper">
                <BrandLogo :brand="brand" :width="62" :height="62" />
              </div>
              <div class="brand-highlight-item_info">
                <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                  <h5
                    class="brand-item_title"
                    :title="brand.company"
                    v-text="brand.company"
                  ></h5>
                </router-link>
                <dl class="brand-item_social">
                  <dd class="brand-highlight-item_n-followers">
                    <span class="dosiin_brand-follow-number">{{
                      brand.follow_count
                    }}</span>
                    người theo dõi
                  </dd>
                  <dd class="brand-highlight-item_n-likes">
                    {{ brand.like_count }} người thích trang
                  </dd>
                </dl>
              </div>
              <div class="brand-highlight-item_follow-btn">
                <FollowButton
                  @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                  class="brand_follow-btn primary-button button"
                  followable_type="brand"
                  :followable_id="brand.company_id"
                  :is_follow="brand.is_follow"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Intersection>

    <Intersection @onIntersection="onIntersectionBrandBestselling()">
      <!-- link="/dosiin-brands" -->

      <BrandHorizonRanking
        title="Thương hiệu bán nhiều"
        link="/brands-bestselling"
        :brands="state.brandsBestSelling"
      />
    </Intersection>

    <Intersection>
      <div class="page-section">
        <div class="section-title-wrapper">
          <h2 class="section-title">Thương hiệu</h2>
        </div>
        <div class="brand-list">
          <BrandLoaderWithProduct
            gridClass="grid grid-50"
            :params="{
              getBrand: true,
              get_banner: true,
              get_products: true,
              get_follow: true,
              page: 1,
              items_per_page: 8,
              use_caching: true,
              sort_by: 'random',
            }"
          />
        </div>
      </div>
    </Intersection>
  </div>
</template>
<script>
import { inject } from "vue";
import SliderBrandSection from "@/components/sliders/BrandsHorizon";
import BrandHorizonRanking from "@/components/brand/BrandHorizonRanking";
import BrandVerticalSkeletonPC from "@/components/skeletons/brand/BrandsHightlightPC";
import BrandItemWithProductPC from "@/components/skeletons/brand/BrandItemWithProductPC";
import BrandLoaderWithProduct from "@/components/brand/BrandLoaderWithProduct";
import MainSlider from "@/components/sliders/MainSlider";
export default {
  components: {
    SliderBrandSection,
    BrandHorizonRanking,
    BrandVerticalSkeletonPC,
    BrandItemWithProductPC,
    BrandLoaderWithProduct,
    MainSlider,
  },
  setup() {
    const state = inject("state");
    const onIntersectionBrandWithBanner = inject("onIntersectionBrandWithBanner");
    const onIntersectionBrandHightlight = inject("onIntersectionBrandHightlight");
    const onIntersectionBrandBestselling = inject("onIntersectionBrandBestselling");
    const options = {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 0,
        stretch: 370,
        depth: 250,
        modifier: 1.5,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
      breakpoints: {
        1600: {
          coverflowEffect: {
            rotate: 0,
            stretch: 850,
            depth: 0,
            modifier: 1,
            scale: 0.8,
            slideShadows: true,
          },
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
    return {
      state,
      onIntersectionBrandWithBanner,
      onIntersectionBrandHightlight,
      onIntersectionBrandBestselling,
      options,
    };
  },
};
</script>
