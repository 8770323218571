<template>
    <div class="brand-list-section">
        <div class="brand-list">
            <div class="brand-list-header">                
                <Skeletor width="30%" />
                <Skeletor class="dosiin_slider-controls" width="20%"/>
            </div>
            <div class="brand-list_alphabet-filter">
                <div class="dosiin_alphabet-filter-swiper alphabet-filter">
                    <ul class="swiper-wrapper dosiin_brand-main-alphabet-tabs">
                        <li class="swiper-slide _letter"> 
                            <Skeletor width="100%" pill />            
                        </li>
                    </ul>
                </div>
            </div>

            <!-- <div class="page-section">
                <ul id="dosiin_brand-list">
                    <li v-for="i in number" :key="i" >
                        <BrandItemWithProduct />
                    </li>
                </ul>
            </div> -->
        </div>
    </div>
</template>

<script>
    // import BrandItemWithProduct from '@/components/skeletons/brand/BrandItemWithProduct';
    
    export default {
        components:{
            // BrandItemWithProduct
        },
        props:{
            number : {
                type : Number,
                default : 4
            }
        },
        setup(props) {
            const number = props.number;
            return {
                number
            }
        },
    }
</script>