<template>
    <section class="page-section">
        <div class="section-title-wrapper">
            <Skeletor class="section-title" width="30%" />
            <Skeletor class="dosiin_slider-controls" width="20%"/>
        </div>
        <div class="grid grid-33">
            <div class="grid-item brand-highlight_list" v-for="i in 9" :key="i">
                <div class="brand-highlight-item_wrapper brand-item">
                    <div class="brand-highlight-item_img-wrapper brand-item_img-wrapper">                    
                        <Skeletor width="76" height="76" circle />              
                    </div>
                    <div class="brand-highlight-item_info">
                        <a> 
                            <h5 class="brand-item_title">
                                <Skeletor width="100%" />
                            </h5>
                        </a>
                        <dl class="brand-item_social">
                            <dd><Skeletor width="100%" /></dd>
                            <dd><Skeletor width="50%" /></dd>
                        </dl>
                    </div>
                    <div class="brand-highlight-item_follow-btn">
                        <button class="brand_follow-btn dosiin_brand_follow-btn">  
                            <Skeletor width="40" height="40" circle/>
                        </button>                
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>